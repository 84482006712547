import React from 'react';
import Header from './components/IncHeader';
import Footer from './components/IncFooter';
import SideNavRooms from './components/SideNavRooms';
import MailchimpSubscribe from "react-mailchimp-subscribe"

const url = "https://Omnibreath.us12.list-manage.com/subscribe/post?u=0b56b61fd2fa94750810216a1&id=a4321607db";

// simplest form (only email)
const SimpleForm = () => <MailchimpSubscribe url={url}/>

// a basic form
const CustomForm = ({ status, message, onValidated }) => {
    let email, fname, lname, phone;
    const submit = () =>
        email &&
        fname &&
        lname &&
        phone &&
        email.value.indexOf("@") > -1 &&
        onValidated({
            EMAIL: email.value,
            FNAME: fname.value,
            LNAME: lname.value,
            PHONE: phone.value
        });

    return (
        <div>
            (* required fields)
            <br />
            <label>*First Name:
                <input type="text"  ref={node => (fname = node)}/>
            </label>
            <label>*Last Name:
                <input type="text"  ref={node => (lname = node)}/>
            </label>
            <label>*Email:
                <input type="text"  ref={node => (email = node)}/>
            </label>
            <label>Phone:
                <input type="text"  ref={node => (phone = node)}/>
            </label>
            <button className={'btn btn-primary'} onClick={submit}> Submit </button>
            <br/>
            {status === "sending" && <div style={{ color: "white" }}>sending...</div>}
            {status === "error" && (
                <div
                    style={{ color: "red" }}
                    dangerouslySetInnerHTML={{ __html: message }}
                />
            )}
            {status === "success" && (
                <div
                    style={{ color: "white" }}
                    dangerouslySetInnerHTML={{ __html: message }}
                />
            )}
        </div>
    );
};

function Contact() {
    const url = "https://Omnibreath.us12.list-manage.com/subscribe/post?u=0b56b61fd2fa94750810216a1&id=a4321607db";
    return (
        <React.Fragment>
            <div className="page-background"></div>
            <Header/>
            <div className="site-wrapper">
                <main id="main-content" className="main-content">
                    <div className="sidebar-content">
                        <div className="content-area clearfix">
                            <h1 className="top-heading">Be One of the Billion:</h1>
                            <div className="inner-content">

                                <p>Do As One holds the vision for one billion people to consciously breathe together in
                                    order to unite the planet, raising the awareness of conscious breathing and its
                                    benefits to humanity.</p>
                                <p>The World Breathing Room is here to be a safe place for all people to unite and
                                    powerfully hold intentions for global greatness together.</p>
                                <p>Join us in breathing and meditating together to create peaceful coexistence on planet
                                    Earth.</p>
                                <p>Be One of the Billion!</p>
                                <div class="form-signup">
                                    <MailchimpSubscribe
                                        url={url}
                                        render={({subscribe, status, message}) => (
                                            <CustomForm
                                                status={status}
                                                message={message}
                                                onValidated={formData => subscribe(formData)}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="side-nav">
                            <SideNavRooms/>
                        </div>
                    </div>
                </main>
                <Footer/>
            </div>
        </React.Fragment>
    );
}
export default Contact;
