import React from 'react';
import Header from './components/IncHeader';
import Footer from './components/IncFooter';
import SideNavRooms from './components/SideNavRooms';


function Contact() {
    return (
        <React.Fragment>
            <div className="page-background"></div>
            <Header/>
            <div className="site-wrapper">
                <main id="main-content" className="main-content">
                    <div className="sidebar-content">
                        <div className="content-area clearfix">
                            <h1 className="top-heading">Contact Us</h1>
                            <div className="inner-content">
                                <p>General Inquiries:  <a href="mailto:support@DoAsOne.com">support@DoAsOne.com</a></p>
                                <p>Rabia Hayek: <a href="mailto:hayek@DoAsOne.com">hayek@DoAsOne.com</a></p>
                                <p>Jaime Collaco: <a href="mailto:collaco@DoAsOne.com">collaco@DoAsOne.com</a></p>
                                <p>Eric Stotz: <a href="mailto:stotz@DoAsOne.com">stotz@DoAsOne.com</a></p>
                            </div>
                        </div>
                        <div className="side-nav">
                            <SideNavRooms/>
                        </div>
                    </div>
                </main>
                <Footer/>
            </div>
        </React.Fragment>
    );
}
export default Contact;
