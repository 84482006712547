
import React from 'react';
// import roomIcon from "../assets/images/side-nav-room-icon-ubr.png";


export default class SideNavSignUp extends React.Component {

    static propTypes = {};

    static defaultProps = {};

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
          <div className="signup-card card mb-3">
              <div className="card-header">
                  Stay Informed
              </div>
              <div className="card-body">
                  <input type="text" placeholder="Enter email address" />
                  <input className="btn btn-primary" type="submit" />
              </div>
          </div>
    );
    }
}
