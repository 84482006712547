import React, { Component } from "react"
import { compose } from "recompose"
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
    InfoWindow
} from "react-google-maps"
import MapService from "../service/MapService";
const mapService = new MapService();
const MapWithAMarker = compose(withScriptjs, withGoogleMap)(props => {

    return (
        <GoogleMap defaultZoom={3} defaultCenter={{ lat: 29.5, lng: -95 }}>
            {props.markers.map(marker => {
                const onClick = props.onClick.bind(this, marker)
                return (
                    <Marker
                        key={marker.id}
                        onClick={onClick}
                        position={{ lat: marker.lat, lng: marker.lon }}
                    >
                        {props.selectedMarker === marker &&
                        <InfoWindow>
                            <div>
                                {marker.ubrMarker}
                            </div>
                        </InfoWindow>}
                    </Marker>
                )
            })}
        </GoogleMap>
    )
})

export default class UbrMap extends Component {
    constructor(props) {
        super(props)
        this.state = {
            peoples: [],
            ubrMarker: false
        }
    }

    componentDidMount() {
        this.countdown4 = setInterval(this.ActionMap, 30000);
        var self = this;
        mapService.getMap().then(function (result) {
            console.log(result);
            self.setState({peoples: result.data});
        });
    }

    ActionMap = () => {
        var self = this;
        mapService.getMap().then(function (result) {
            console.log(result);
            self.setState({peoples: result.data});
        });
    };

    handleClick = (marker, event) => {
        // console.log({ marker })
        this.setState({ selectedMarker: marker })
    }
    render() {
        return (
            <MapWithAMarker
                selectedMarker={this.state.selectedMarker}
                markers={this.state.peoples}
                onClick={this.handleClick}
                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDD2kR5OR2ZYZIZCiEEZkuDe313_szmdyA&v=3.exp&libraries=geometry,drawing,places"
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `400px` }} />}
                mapElement={<div style={{ height: `100%` }} />}
            />
        )
    }
}
