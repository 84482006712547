import React from 'react';
import Header from './components/IncHeader';
import Footer from './components/IncFooter';
import SideNavRooms from './components/SideNavRooms';


function Contact() {
    return (
        <React.Fragment>
            <div className="page-background"></div>
            <Header/>
            <div className="site-wrapper">
                <main id="main-content" className="main-content">
                    <div className="sidebar-content">
                        <div className="content-area clearfix">
                            <h1 className="top-heading">Why Breathe Together?</h1>
                            <div className="inner-content">

                                <p>When we breathe together deeply, synchronously and consciously, we are able to feel a greater sense of peace, health and unity almost immediately. An instant reduction in stress, increased energy levels, greater circulation, less anxiety and greater vitality are just a few of the physical effects that result from increasing our breathing intelligence.</p>
                                <br />
                                <p class="text-center">We are living in evolutionary times.</p>
                                <br />
                                <p>By educating people about conscious breathing, we are able to empower individuals to create greater health through a simple practice.</p>
                                <p>Historically, from the Hawaiians to the Maori people of New Zealand, the early Christians to the Taoists, as well as Yogic, Tantric and meditation practices all over the world, humans have breathed together consciously to create unity and to connect to their innate, collective power of Oneness.</p>

                            </div>
                        </div>
                        <div className="side-nav">
                            <SideNavRooms/>
                        </div>
                    </div>
                </main>
                <Footer/>
            </div>
        </React.Fragment>
    );
}
export default Contact;
