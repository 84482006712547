import React from 'react';
import Header from './components/IncHeader';
import Footer from './components/IncFooter';
import SideNavRooms from './components/SideNavRooms';


function Contact() {
    return (
        <React.Fragment>
            <div className="page-background"></div>
            <Header/>
            <div className="site-wrapper">
                <main id="main-content" className="main-content">
                    <div className="sidebar-content">
                        <div className="content-area clearfix">
                            <h1 className="top-heading">About Do As One</h1>
                            <div className="inner-content">

                                <p>Do As One is a global community of people that breathes together consciously to cultivate peace, oneness and harmonious co-existence. Our community includes teachers, breathworkers and healers of all types from all over the world.</p>
                                <p>Do As One was born on 07/07/07, breathing life into humanity through the Internet and uniting people in conscious breathing through computers and apps for the first time ever.</p>
                                <p>The World Breathing Room (WBR) is here to continue fulfilling the vision of synchronizing One Billion People in breathing together.</p>

                            </div>
                        </div>
                        <div className="side-nav">
                            <SideNavRooms/>
                        </div>
                    </div>
                </main>
                <Footer/>
            </div>
        </React.Fragment>
    );
}
export default Contact;
