
import React from 'react';
import { Navbar, Dropdown, Container, Row, Col, Button } from 'react-bootstrap';
import {
    number,
} from 'prop-types';
import logo from '../assets/images/logo.png';

export default class Incheader extends React.Component {

    static propTypes = {
        //startIndex : number,
    };

    static defaultProps = {
        //startIndex: 1,
    };

    constructor(props) {
        super(props);
        this.state = {
            //currentIndex: props.startIndex,
        };
    }

    render() {
        return (
            <header id="header" className="header">
            <Navbar expand="lg" variant="dark">
                <div className="header-top">
                    <a className="logo" href="/">
                        <img src={logo} alt=""/>
                    </a>
                    <div className="eyebrow">

                        {/*<a href="/">Get Involved</a>*/}

                        <form class="paypal-donate" action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                            <input type="hidden" name="cmd" value="_s-xclick" />
                            <input type="hidden" name="hosted_button_id" value="#####" />
                            <input type="button" border="0" name="submit" value="Donate" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
                            <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
                        </form>


                        <Navbar.Toggle className="navbar-toggler" type="button" data-toggle="collapse" data-target="#main-nav"
                                aria-controls="main-nav" aria-expanded="false" aria-label="Toggle navigation">
                            <span></span>
                            <span></span>
                            <span></span>
                        </Navbar.Toggle>
                    </div>
                </div>
                <Navbar.Collapse id="main-nav" className="main-nav collapse navbar-collapse">
                    <div className="navbar-nav nav-fill">

                        <div className="nav-item">
                            <a className="nav-link text-left text-lg-center" href="/be-one-of-the-billion">Be One of the Billion</a>
                        </div>
                        <div className="nav-item">
                            <a className="nav-link text-left text-lg-center" href="/about-do-as-one">About Do As One</a>
                        </div>
                        <div className="nav-item">
                            <a className="nav-link text-left text-lg-center" href="/why-breathe-together">Why Breathe Together?</a>
                        </div>
                        <div className="nav-item">
                            <a className="nav-link text-left text-lg-center" href="/the-grand-intentions">The Grand Intentions</a>
                        </div>
                        <div className="nav-item">
                            <a className="nav-link text-left text-lg-center" href="/contact-us">Contact Us</a>
                        </div>
                    </div>
                </Navbar.Collapse>
            </Navbar>
        </header>
        );
    }
}
