
import React from 'react';
import {
    number,
} from 'prop-types';


export default class Incfooter extends React.Component {

    static propTypes = {
        //startIndex : number,
    };

    static defaultProps = {
        //startIndex: 1,
    };

    constructor(props) {
        super(props);
        this.state = {
            //currentIndex: props.startIndex,
        };
    }

    render() {
        return (
            <footer id="footer" className="footer mb-3">
                <nav className="navbar navbar-expand-lg navbar-dark w-100">
                    <div className="container-fluid px-0">
                        <div className="navbar-brand">
                            Copyright Do As One
                        </div>
                        <nav className="nav">
                            <a className="nav-link" href="#">Link 1</a>
                            <a className="nav-link" href="#">Link 2</a>
                        </nav>
                    </div>
                </nav>
            </footer>
    );
    }
}
