import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams
} from "react-router-dom";
import './scss/App.scss';
import Contact from "./_Contact";
import BeOneOfTheBillion from "./_BeOneOfTheBillion";
import AboutDoAsOne from "./_AboutDoAsOne";
import WhyBreatheTogether from "./_WhyBreatheTogether";
import TheGrandIntentions from "./_TheGrandIntentions";
// import OurStory from "./OurStory";
// import Vision from "./Vision";
// import GrandIntentions from "./GrandIntentions";
// import Founders from "./Founders";
// import Ambassadors from "./Ambassadors";
// import GetInvolved from "./GetInvolved";
// import Share from "./Share";
// import DefinitionProject from "./DefinitionProject";
// import Quotes from "./Quotes";
// import Conspire from "./Conspire";
// import Breathing from "./Breathing";
// import Calls from "./Calls";
// import Colors from "./Colors";
// import Mass from "./Mass";
// import Contact from "./Contact";
// import Testimonials from "./Testimonials";
import Ubr from "./Ubr";
import Home from "./Home";
export default function App() {
    return (
        <div className="App">
            <Router>
                {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
                <Switch>
                    <Route path="/be-one-of-the-billion" render={() => <BeOneOfTheBillion/>}/>
                    <Route path="/about-do-as-one" render={() => <AboutDoAsOne/>}/>
                    <Route path="/why-breathe-together" render={() => <WhyBreatheTogether/>}/>
                    <Route path="/the-grand-intentions" render={() => <TheGrandIntentions/>}/>
                    <Route path="/contact-us" render={() => <Contact/>}/>
                    {/*
                    <Route path="/our-story" render={() => <OurStory/>}/>
                    <Route path="/vision" render={() => <Vision/>}/>
                    <Route path="/13-grand-intentions" render={() => <GrandIntentions/>}/>
                    <Route path="/founders" render={() => <Founders/>}/>
                    <Route path="/ambassadors-of-breath" render={() => <Ambassadors/>}/>
                    <Route path="/get-involved" render={() => <GetInvolved/>}/>
                    <Route path="/share" render={() => <Share/>}/>
                    <Route path="/definition-project" render={() => <DefinitionProject/>}/>
                    <Route path="/quotes" render={() => <Quotes/>}/>
                    <Route path="/conspire" render={() => <Conspire/>}/>
                    <Route path="/breathing" render={() => <Breathing/>}/>
                    <Route path="/calls" render={() => <Calls/>}/>
                    <Route path="/colors" render={() => <Colors/>}/>
                    <Route path="/mass" render={() => <Mass/>}/>
                    <Route path="/contact" render={() => <Contact/>}/>
                    <Route path="/testimonials" render={() => <Testimonials/>}/>
                    <Route path="/" render={() => <Home/>}/>
                    */}
                    <Route path="/" render={() => <Ubr/>}/>
                </Switch>
            </Router>
        </div>
    );
}
