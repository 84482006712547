import React from 'react';
import Header from './components/IncHeader';
import Footer from './components/IncFooter';
import SideNavRooms from './components/SideNavRooms';


function Contact() {
    return (
        <React.Fragment>
            <div className="page-background"></div>
            <Header/>
            <div className="site-wrapper">
                <main id="main-content" className="main-content">
                    <div className="sidebar-content">
                        <div className="content-area clearfix">
                            <h1 className="top-heading">The Grand Intentions</h1>
                            <div className="inner-content">

                                <div className="content-block">
                                    <div className="content-block-left">
                                        <p>Do As One has created a shared energy grid of focused positive intentions fueled by conscious, synchronous breaths.</p>
                                        <p>We are constantly viewing and breathing-in fear-based, negative news and impressions all over the media and Internet daily. This affects our global nervous system response and all of human consciousness.</p>
                                        <p>Every hour, on the hour, for one minute, a single intention is focused on with the mind and breathed-in with the body. As more and more people add to the collective participation, a shared energy grid of powerful human intent is created in the World Breathing Room.</p>
                                    </div>

                                    <div className="content-block-right">
                                        <p>The Grand Intentions (EST) on the left & (GMT) on the right</p>
                                        <table>
                                            <tr> <td>12:00</td> <td>Unconditional Love</td> <td>7:00</td> </tr>
                                            <tr> <td>01:00</td> <td>Nourishment</td> <td>8:00</td> </tr>
                                            <tr> <td>02:00</td> <td>Peace</td> <td>9:00</td> </tr>
                                            <tr> <td>03:00</td> <td>Health</td> <td>10:00</td> </tr>
                                            <tr> <td>04:00</td> <td>Oneness</td> <td>11:00</td> </tr>
                                            <tr> <td>05:00</td> <td>Harmony</td> <td>12:00</td> </tr>
                                            <tr> <td>06:00</td> <td>Compassion</td> <td>13:00</td> </tr>
                                            <tr> <td>07:00</td> <td>Joy</td> <td>14:00</td> </tr>
                                            <tr> <td>08:00</td> <td>Beauty</td> <td>15:00</td> </tr>
                                            <tr> <td>09:00</td> <td>Abundance</td> <td>16:00</td> </tr>
                                            <tr> <td>10:00</td> <td>Gratitude</td> <td>17:00</td> </tr>
                                            <tr> <td>11:00</td> <td>Forgiveness</td> <td>18:00</td> </tr>
                                            <tr> <td>12:00</td> <td>Unconditional Love</td> <td>19:00</td> </tr>
                                            <tr> <td>13:00</td> <td>Nourishment</td> <td>20:00</td> </tr>
                                            <tr> <td>14:00</td> <td>Peace</td> <td>21:00</td> </tr>
                                            <tr> <td>15:00</td> <td>Health</td> <td>22:00</td> </tr>
                                            <tr> <td>16:00</td> <td>Oneness</td> <td>23:00</td> </tr>
                                            <tr> <td>17:00</td> <td>Harmony</td> <td>24:00</td> </tr>
                                            <tr> <td>18:00</td> <td>Compassion</td> <td>1:00</td> </tr>
                                            <tr> <td>19:00</td> <td>Joy</td> <td>2:00</td> </tr>
                                            <tr> <td>20:00</td> <td>Beauty</td> <td>3:00</td> </tr>
                                            <tr> <td>21:00</td> <td>Abundance</td> <td>4:00</td> </tr>
                                            <tr> <td>22:00</td> <td>Gratitude</td> <td>5:00</td> </tr>
                                            <tr> <td>23:00</td> <td>Forgiveness</td> <td>6:00</td> </tr>
                                        </table>
                                        <p>Please use the Time Zone Converter below to join in from your location.</p>
                                        <p><a href="https://www.thetimezoneconverter.com" target="_blank">Time Zone Converter</a></p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="side-nav">
                            <SideNavRooms/>
                        </div>
                    </div>
                </main>
                <Footer/>
            </div>
        </React.Fragment>
    );
}
export default Contact;
