import React from 'react';
import {Container, Row, Col, Button} from 'react-bootstrap';
import ReactDOM from "react-dom";
import Moment from 'moment';
import 'moment-timezone';
import momentDurationFormatSetup from "moment-duration-format";
import { compose, withProps } from "recompose";
import OrbColor from '../assets/ubr/ubrcolorLayer.png'; // Tell webpack this JS file uses this image
import OrbTop from '../assets/ubr/urbtopLayer.png'; // Tell webpack this JS file uses this image
import OrbBack from '../assets/ubr/ubrbackGlow.png';
import Intro from '../assets/audio/instruc.mp3';
import BreaIn from '../assets/audio/6bpmi.wav';
import BreaOut from '../assets/audio/6bpme.wav';
import MyMapComponent from '../components/IncMap'
import MapService from "../service/MapService";
import {
    bool,
    number,
    string,
} from 'prop-types';
let Aintro = new Audio(Intro);
let ABreaIn = new Audio(BreaIn);
let ABreaOut = new Audio(BreaOut);
/*const screenChangeEvents = [
    'fullscreenchange',
    'MSFullscreenChange',
    'mozfullscreenchange',
    'webkitfullscreenchange',
];*/

const URL = 'ws://doasone.com:8000/ws/ubr/test/';
const mapService = new MapService();
export default class IncUbr extends React.Component {

    ws = new WebSocket(URL);

    static propTypes = {
        startIndex: number,
        orbState: number,
        orbInstructionCss: string,
        orbSyncContainerCss: string,
        orbCountContainerCss: string,
        orbBarContainerCss: string,
        orbBarTimerContainerCss: string,
        orbIntentContainerCss: string,
        orbContainerCss: string,
        orbImageCss: string,
        orbColorCss: string,
        orbTopCss: string,
        orbBackCss: string,
        orbBreathStatus: string,
        orbSyncTextCss: string,

    };

    static defaultProps = {
        startIndex: 1,
        orbState: 0,
        orbInstructionCss: 'OrbInstructContainer show',
        orbSyncContainerCss: 'OrbSyncContainer',
        orbCountContainerCss: 'OrbCountContainer',
        orbBarContainerCss: 'OrbBarContainer',
        orbBarTimerContainerCss:'OrbBarTimerContainer',
        orbIntentContainerCss: 'OrbIntentContainer',
        orbContainerCss: 'OrbContainer',
        orbImageCss: 'OrbImage',
        orbColorCss: 'OrbColor',
        orbTopCss: 'OrbTop',
        orbBackCss: 'OrbBack',
        orbBreathStatus: '',
        orbSyncTextCss: 'OrbSyncText',
    };

    constructor(props) {
        super(props);
        this.state = {
            currentIndex: props.startIndex,
            introstate: 0,
            orbState: props.orbState,
            orbInstructionCss: props.orbInstructionCss,
            orbSyncContainerCss: props.orbSyncContainerCss,
            orbBarContainerCss: props.orbBarContainerCss,
            orbBarTimerContainerCss : props.orbBarTimerContainerCss,
            orbIntentContainerCss: props.orbIntentContainerCss,
            orbCountContainerCss: props.orbCountContainerCss,
            orbContainerCss: props.orbContainerCss,
            orbImageCss: props.orbImageCss,
            orbColorCss: props.orbColorCss,
            orbTopCss: props.orbTopCss,
            orbBackCss: props.orbBackCss,
            orbBreathStatus: props.orbBreathStatus,
            orbSyncTextCss: props.orbSyncTextCss,
            orbintention: '',
            timeLeft: 0,
            timeOn: 0,
            curTime: null,
            curOTime: 0,
            isFullscreen: false,
            pcount: 0,
        };

        setInterval( () => {
            this.setState({
                curTime : Moment().tz('America/New_York')
            })

            momentDurationFormatSetup(Moment);
            var durationSeconds = this.state.timeOn;
            var durationdisp = Moment.duration(durationSeconds, "seconds").format("hh:mm:ss");
            this.setState({
                timeOn : this.state.timeOn + 1
        })
            this.setState({
                curOTime: durationdisp
        })
        },1000)
        /*this.loadedImages = {};
        this.imageGallery = React.createRef();*/
    }

    componentDidMount() {
        this.countdown3 = setInterval(this.ActionMap, 30000);
        this.ws.onopen = () => {
            // on connecting, do nothing but log it to the console
            console.log('connected')
        }

        this.ws.onmessage = evt => {
            // on receiving a message, add it to the list of messages
            const message = JSON.parse(evt.data)
            console.log(message);
            if (message.type === 'chat_message') {
                if (this.state.introstate == 1 || this.state.introstate == 2) {
                    clearInterval(this.countdown2);
                    this.setState({timeLeft: 12});
                    this.countdown2 = setInterval(this.timerText, 1000);
                }
                if (this.state.introstate == 2 || this.state.introstate == 3) {
                    this.setState({introstate: 3});
                    clearInterval(this.countdown2);
                    this.setState({timeLeft: 12});
                    this.countdown2 = setInterval(this.timerText, 1000);
                    if (this.state.introstate == 3){
                        clearInterval(this.countdown2);
                        this.setState({timeLeft: 0});
                        this.setState({orbSyncContainerCss: 'OrbSyncContainer'});
                        this.setState({introstate: 4});
                        this.countdown1 = setInterval(this.timer, 6000);
                    }
                }
                if(message.intent != ''){
                    this.state.orbintention = message.intent
                    this.setState({orbIntentContainerCss: ' OrbIntentContainer show'})
                }else{
                    this.state.orbintention = message.intent
                    this.setState({orbIntentContainerCss: ' OrbIntentContainer'})
                }
            }
        }

        this.ws.onclose = () => {
            console.log('disconnected')
            // automatically try to reconnect on connection loss
            this.setState({
                ws: new WebSocket(URL),
            })
        }
    }

    ActionLink = () => {
        this.setState({orbInstructionCss: 'OrbInstructContainer'});
        this.setState({orbContainerCss: 'OrbContainer show'});
        this.setState({orbIntentContainerCss: ' OrbIntentContainer'})
        Aintro.play();
        ABreaIn.volume = 0;
        ABreaIn.play()
        ABreaOut.volume = 0;
        ABreaOut.play()
        Aintro.play();
        this.setState({introstate: 1});
        Aintro.onended = () => {
            this.setState({orbSyncContainerCss: 'OrbSyncContainer show'});
            this.setState({introstate: 2});

        };
        console.log('The link was clicked.');
    };

    ActionMap = () => {
        var self = this;
        mapService.getMap().then(function (result) {
            console.log(result.data.length)
            self.setState({pcount: result.data.length});
        });
    };

    timer = () => {
        ABreaIn.volume = 1;
        ABreaOut.volume = 1;
        this.setState({orbCountContainerCss: 'OrbCountContainer show'})
        this.setState({orbBarContainerCss: 'OrbBarContainer show'})
        this.setState({orbBarTimerContainerCss: 'OrbBarTimerContainer show'})
        clearInterval(this.countdown2);
        this.setState({timeLeft: 6});
        this.countdown2 = setInterval(this.timerText, 1000);
        if (this.state.orbState === 0) {
            this.setState({orbSyncTextCss: 'OrbSyncText'});
            this.breathout();
            this.setState({orbBreathStatus: 'Exhale'});
            ABreaOut.play();
        } else if (this.state.orbState === 1) {
            this.breathout();
            this.setState({orbBreathStatus: 'Exhale'});
            ABreaOut.play();
        } else {
            this.breathin();
            this.setState({orbBreathStatus: 'Inhale'});
            ABreaIn.play();
        }
    };


    timerText = () => {
        let seconds = this.state.timeLeft - 1;
        this.setState({
            timeLeft: seconds,
        });
        // Check if we're at zero.
        if (seconds == 0) {
            clearInterval(this.timerText());
        }
    };

    breathin = () => {
        this.setState({orbImageCss: 'OrbImage'});
        this.setState({orbColorCss: 'OrbColor'});
        this.setState({orbTopCss: 'OrbTop'});
        this.setState({orbBackCss: 'OrbBack'});
        this.setState({orbState: 1});
    };

    breathout = () => {
        this.setState({orbImageCss: 'OrbImage Exhale'});
        this.setState({orbColorCss: 'OrbColor Exhale'});
        this.setState({orbTopCss: 'OrbTop Exhale'});
        this.setState({orbBackCss: 'OrbBack Exhale'});
        this.setState({orbState: 2});
    };

    toggleFullscreen = () => {
        this.setState({isFullscreen: !this.state.isFullscreen});
    };





    render() {
        Moment.locale('en');
     return (
            <div>
                <div className={`OrbWrapper ${this.state.isFullscreen ? "fullscreen" : ""}`}>
                    <div className={this.state.orbInstructionCss}>
                        <h1 className="text-center mb-4">World Breathing Room (WBR)</h1>
                        <p className="text-center mb-5">The World Breathing Room enables you to breathe synchronously with people from around
                            the world.</p>
                        <Button variant="light" onClick={this.ActionLink}>Enter Room</Button>
                    </div>
                    <div className={this.state.orbContainerCss}>
                        <div className="OrbContainerTop">
                            <div className={this.state.orbBarTimerContainerCss}>
                                Time: {this.state.curOTime}
                            </div>
                            <div className="OrbBreathStatus">
                                <p>{this.state.orbBreathStatus}</p>
                            </div>
                        </div>
                        <div className="OrbContainerMiddle">
                            <div className={this.state.orbImageCss}>
                                <img src={OrbColor} className={this.state.orbColorCss} alt="UBR Orb"/>
                                <img src={OrbTop} className={this.state.orbTopCss} alt="UBR Orb"/>
                                <img src={OrbBack} className={this.state.orbBackCss} alt="UBR Orb"/>
                            </div>
                            <div className={this.state.orbSyncContainerCss}>
                                <div className="OrbCountCircle"><div>{this.state.timeLeft}</div></div>
                                <p>Syncing to the World Breathing Rhythm... <br/> Prepare to exhale...</p>
                            </div>
                            <div className={this.state.orbIntentContainerCss}>
                                <p>{this.state.orbintention}</p>
                            </div>
                        </div>
                        <div className="OrbContainerBottom">
                            <div className={this.state.orbCountContainerCss}>
                                <div className="OrbCountCircle">{this.state.timeLeft}</div>
                                <p>Seconds till your next {this.state.orbBreathStatus}</p>
                            </div>
                            <div className={this.state.orbBarContainerCss}>
                                <div className="room-name">
                                    <p>WORLD BREATHING ROOM</p>
                                </div>
                                <div className="room-info">
                                    <span className="room-time">Time: {Moment(this.state.curTime).format('hh:mma')}</span>
                                    <span className="room-people">People: {this.state.pcount}</span>
                                </div>
                                <div className="room-fullscreen">
                                    <button onClick={this.toggleFullscreen.bind(this)}>{!this.state.isFullscreen ? "Fullscreen" : "Minimize"}</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <MyMapComponent isMarkerShown />
            </div>
        );
    }
}
