import React from 'react';
import Header from './components/IncHeader';
import Footer from './components/IncFooter';
import SideNavRooms from './components/SideNavRooms';
import SideNavSignUp from './components/SideNavSignUp';
import Slider from "react-slick";
import slide01 from "./assets/images/home/slide01.jpg";
import slide02 from "./assets/images/home/slide02.jpg";
import slide03 from "./assets/images/home/slide03.jpg";
import slide04 from "./assets/images/home/slide04.jpg";
import slide05 from "./assets/images/home/slide05.jpg";
import slide06 from "./assets/images/home/slide06.jpg";
import slide07 from "./assets/images/home/slide07.jpg";
import slide08 from "./assets/images/home/slide08.jpg";

class HomeSlider extends React.Component {
  render() {
    var settings = {
      autoplay: true,
      autoplaySpeed: 8000,
      speed: 800,
      arrows: false,
      dots: true,
      pauseOnDotsHover: true,
      pauseOnHover: true,
      pauseOnFocus: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    return (
      <Slider {...settings}>
        <div>
          <a href="https://itunes.apple.com/us/app/breathe-together-hd/id659995401?mt=8">
            <img src={slide01} alt="" />
          </a>
        </div>
        <div>
          <a href="#">
            <img src={slide02} alt="" />
          </a>
        </div>
        <div>
          <a href="#">
            <img src={slide03} alt="" />
          </a>
        </div>
        <div>
          <a href="#">
            <img src={slide04} alt="" />
          </a>
        </div>
        <div>
          <a href="#">
            <img src={slide05} alt="" />
          </a>
        </div>
        <div>
          <a href="#">
            <img src={slide06} alt="" />
          </a>
        </div>
        <div>
          <a href="#">
            <img src={slide07} alt="" />
          </a>
        </div>
        <div>
          <a href="#">
            <img src={slide08} alt="" />
          </a>
        </div>
      </Slider>
    );
  }
}

function Home() {
    return (
        <React.Fragment>
            <div className="page-background"></div>
            <Header/>
            <div className="site-wrapper">
                <main id="main-content" className="main-content">
                    <div className="sidebar-content">
                        <div className="content-area clearfix">

                            <div className="home-intro-slideshow">
                                <HomeSlider/>
                            </div>

                            <div className="home-intro">

                              <div className="info-block">
                                <div className="info-block-inner">
                                  <a href="http://itunes.apple.com/us/app/breathe-together/id475959214?mt=8" className="home-phones">
                                    <p>Get the Do As One Mobile App</p>
                                  </a>
                                </div>
                              </div>

                              <div className="info-block">
                                <div className="info-block-inner home-video">
                                  <div className="youtube-iframe">
                                      <iframe src="https://www.youtube.com/embed/bNJhaLQ6NyM" allow="autoplay; encrypted-media" allowfullscreen=""></iframe>
                                  </div>
                                </div>
                              </div>

                              <div className="home-intentions">
                                <h3>The Next Grand Intention Is</h3>
                                <p>Conscious Breathing in 18 min and 30 seconds</p>
                              </div>

                            </div>

                        </div>
                        <div className="side-nav">
                            <SideNavRooms/>
                            <SideNavSignUp/>
                        </div>
                    </div>
                </main>
                <Footer/>
            </div>
        </React.Fragment>
    );
}
export default Home;
