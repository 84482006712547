import React from 'react';
import Header from './components/IncHeader'
import Footer from './components/IncFooter'
import Room from './components/IncUbr'
function Ubr() {
    return (
        <React.Fragment>
            <div className="page-background"></div>
            <Header/>
            <div className="site-wrapper">
                <main id="main-content" className="main-content">
                    <div className="full-width-content">
                        <div className="content-wrapper">
                            <div className="inner-content">
                           <div className="room-description" style={{display: "none", textAlign: "center"}}>
                                <a>Do As One 2020.</a>
                            </div>
                            </div>
                            <Room />
                            <div className="inner-content">
                                <div className="room-description">
                                    <h2>The World Breathing Room (WBR) -&nbsp;LIVE</h2>
                                    <p>Do As One invites you to experience connection by breathing together synchronously with everyone currently logged into the WBR. The map on this page shows you where everyone you are breathing with is located around the world in real time. At a pace of six breaths per minute, instill the power of conscious, deep breathing as you oxygenate your body, heighten awareness and increase your overall wellness.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <Footer/>
            </div>
        </React.Fragment>
    );
}
export default Ubr;
