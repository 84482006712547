
import React from 'react';
import roomIcon from "../assets/images/side-nav-room-icon-ubr.png";


export default class SideNavRooms extends React.Component {

    static propTypes = {};

    static defaultProps = {};

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
          <div className="rooms-card card mb-3">
              <div className="card-header">
                  Breathing Rooms
              </div>
              <div className="card-body">

                  <a className="room-box" href="/ubr">
                      <div className="media">
                          <img className="room-icon" src={roomIcon}
                               alt=""/>
                          <div className="media-body">
                              <p className="room-name">World Breathing Room <span
                                  className="live">Live</span></p>
                          </div>
                      </div>
                      <div className="media">
                          <div className="enter-button">Enter</div>
                          <div className="media-body">
                              { /*<div className="people-button">10,305</div>*/ }
                          </div>
                      </div>
                  </a>
              </div>
          </div>
    );
    }
}
